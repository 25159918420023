import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FiltersButton extends Vue {
  @Prop({ required: false })
  list!: any
  @Prop({ required: false })
  headers!: any

  showFilters = false
  filterValue = ''
  objKeys: any[] = []
  data: any[] = []
  dataCopy: any[] = []
  keySelected = ''
  showValueFilter = false
  filtersArrays: any[] = []
  keyFiltersArrays: any[] = []
  labelOfKey = ''

  @Watch('list')
  onPropertyChanged() {
    if (this.data.length == 0) {
      this.dataCopy = this.list
    }
    this.data = this.list
  }

  showInputs() {
    this.showFilters = true
    this.saveKeys()
  }

  showFilterValues() {
    this.showValueFilter = true
    this.keyFiltersArrays.push(this.keySelected)
    this.labelOfKey = this.headers.filter(
      (x: any) => x.field == this.keySelected,
    )[0].label
    //this.saveKeys()
  }

  saveKeys() {
    //this.objKeys = Object.keys(this.data[0]) .map((x:any) => x.field)
    this.objKeys = this.headers
  }

  addFilter() {
    this.filtersArrays.push(this.filterValue)
    this.showValueFilter = false
    this.filterValue = ''
    this.keySelected = ''
    this.applyFilters()
  }

  deleteFilter(index: number) {
    this.filtersArrays.splice(index, 1)
    this.keyFiltersArrays.splice(index, 1)
    this.data = this.dataCopy
    this.applyFilters()
  }

  clearKeys() {
    this.showFilters = false
    this.keySelected = ''
  }

  clearValue() {
    this.showValueFilter = false
    this.filterValue = ''
    this.keySelected = ''
    this.keyFiltersArrays = this.keyFiltersArrays.splice(
      this.keyFiltersArrays.length - 1,
      1,
    )
  }

  applyFilters() {
    let data: any[] = []
    const integrations: any = {}
    if (this.filtersArrays.length > 0) {
      this.filtersArrays.forEach((filterField: any, index: number) => {
        const key = this.keyFiltersArrays[index] as string
        if (!Object.keys(integrations).includes(key))
          integrations[`${key}`] = []
        integrations[`${key}`].push(this.filtersArrays[index])
      })
      const keys_arr = Object.keys(integrations)
      let filters = ''

      keys_arr.forEach((filterField: string, index: number) => {
        filters += `integrations.${filterField}.includes(y.${filterField}) ${
          keys_arr.length - 1 == index ? '' : '&&'
        } `
      })
      data = this.dataCopy.filter((y: any) => eval(filters))
    } else {
      data = this.dataCopy
    }
    this.$emit('filterItems', data)
  }

  get filteredKey() {
    return this.objKeys.filter((option: any) => {
      return (
        option
          .toString()
          .toLowerCase()
          .indexOf(this.keySelected.toLowerCase()) >= 0
      )
    })
  }

  get filteredValue() {
    let data = this.dataCopy.map((x: any) => x[this.keySelected])
    data = [...new Set(data)]
    return data.filter((option: any) => {
      return (
        option
          .toString()
          .toLowerCase()
          .indexOf(this.filterValue.toLowerCase()) >= 0
      )
    })
  }
}
